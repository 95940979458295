import React from "react";

const DownloadApp = props => {
  return (
    <div className="bg-[#be5200] flex items-center justify-between px-1 py-1">
      <div className="flex items-center justify-between">
        <img
          src="/static/icons/favicon.png"
          fetchpriority="high"
          decoding="async"
          alt="Tải ứng dụng Mua Sỉ"
          className="rounded-md border border-white w-8 h-8 float-left"
        />
        <div className="text-white mx-2">
          <div className="text-base font-medium">Tải app Thị Trường Sỉ</div>
          <div className="text-xs">Nhập hàng trực tiếp từ nhà sản xuất</div>
        </div>
      </div>

      <a
        id="download-app-link"
        className="px-4 py-1 text-[#f58220] uppercase font-bold rounded-full bg-white flex-shrink-0"
        href="https://go.thitruongsi.com/GgOeL"
      >
        Tải ngay
      </a>
    </div>
  );
};

export const DownloadAppSquare = ({ onDownload }) => {
  return (
    <div className="bg-[#be5200] flex flex-col justify-between px-1 py-1 rounded-lg">
      <div className="bg-[#be5200] flex items-center justify-between px-1 py-1">
        <div className="flex items-center justify-between">
          <img
            src="/static/icons/favicon.png"
            fetchpriority="high"
            decoding="async"
            alt="Tải ứng dụng Mua Sỉ"
            className="rounded-md border border-white w-12 h-12 float-left"
          />
          <div className="text-white mx-2">
            <div className="text-lg font-medium">Tải app Thị Trường Sỉ</div>
            <div className="text-sm">
              Nhắn tin, đặt hàng trực tiếp với nhà cung cấp
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 mb-2 self-center">
        {onDownload ? (
          <button
            id="download-app-link"
            className="px-8 py-2 text-[#f58220] uppercase font-bold rounded-md bg-white flex-shrink-0"
            onClick={onDownload}
          >
            Tải ngay
          </button>
        ) : (
          <a
            id="download-app-link"
            className="px-8 py-2 text-[#f58220] uppercase font-bold rounded-md bg-white flex-shrink-0"
            href="https://go.thitruongsi.com/GgOeL"
          >
            Tải ngay
          </a>
        )}
      </div>
    </div>
  );
};

export default DownloadApp;
