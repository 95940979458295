import React from "react";
import PropTypes from "prop-types";

const Tag = ({ onClick, children }) => (
  <span className="ts-tag" onClick={onClick}>
    {children}
  </span>
);

Tag.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default React.memo(Tag);
