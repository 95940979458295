import { useEffect, useCallback } from "react";
import makeEvent from "../api/Analytics/inhouseAnalytics";
import { useSelector } from "react-redux";

const useInhouseAnalytics = (event_type, data = {}) => {
  const deviceOS = useSelector(state => state.common.deviceOS);
  const isMobileApp = useSelector(state => state.common.isMobileApp);
  const authToken = useSelector(state => state.user.token);
  const user = useSelector(state => state.user.user);
  const isSearchEngineBot = useSelector(
    state => state.common.isSearchEngineBot
  );

  useEffect(() => {
    if (authToken && !user.id) {
      return;
    }
    if (isSearchEngineBot) {
      return;
    }

    const getCurrentPlatform = () => {
      if (isMobileApp && deviceOS) {
        return deviceOS === "android" ? `and_buyer` : `${deviceOS}_buyer`;
      }

      return "web";
    };

    const currentPlatform = getCurrentPlatform();

    switch (event_type) {
      case "product_view":
        if (data.is_loading) {
          break;
        }
        makeEvent({
          ...getCommonInfo(user),
          action: "view",
          platform: currentPlatform,
          category: data.is_classifieds ? "classifieds" : "product",
          object_type: data.is_classifieds ? "classifieds" : "product",
          object_id: data.product_id,
          object_title: data.product_name,
          object_metadata: {
            page: window.location.href,
            product_id: data.product_id,
            product_name: data.product_name,
            shop_id: data.shop_id,
            recomm_id: data.recomm_id,
          },
        });
        break;

      case "shop_view":
        makeEvent({
          ...getCommonInfo(user),
          action: "view",
          platform: currentPlatform,
          category: "shop",
          object_id: data.shop_id,
          object_type: "shop",
          object_title: data.shop_name,
          object_metadata: {
            page: window.location.href,
            shop_id: data.shop_id,
          },
        });
        break;

      case "shop_products":
        makeEvent({
          ...getCommonInfo(user),
          action: "view",
          platform: currentPlatform,
          category: "shop_products",
          object_id: data.shop_id,
          object_type: "shop",
          object_title: data.shop_name,
          object_metadata: {
            page: window.location.href,
            shop_id: data.shop_id,
          },
        });
        break;

      case "category":
        makeEvent({
          ...getCommonInfo(user),
          action: "view",
          platform: currentPlatform,
          category: "category",
          object_type: "category",
          object_id: data.object_id,
          object_title: data.object_title,
          object_metadata: {
            page: window.location.href,
          },
        });
        break;

      case "homepage":
        makeEvent({
          ...getCommonInfo(user),
          action: "view",
          platform: currentPlatform,
          category: "homepage",
          object_type: "page",
          object_metadata: {
            page: "https://thitruongsi.com/",
          },
        });
        break;

      case "page":
        makeEvent({
          ...getCommonInfo(user),
          action: "view",
          platform: currentPlatform,
          category: "page",
          object_type: "page",
          object_metadata: {
            page: window.location.href,
          },
        });
        break;

      case "search":
        makeEvent({
          ...getCommonInfo(user),
          action: "view",
          platform: currentPlatform,
          category: "search",
          object_type: "page",
          object_title: data.keyword,
          object_metadata: {
            page: window.location.href,
            query: data.keyword,
          },
          referrer: document.referrer,
        });
        break;

      default:
        break;
    }
  }, [user.id, data.product_id, data.shop_id, event_type, data.object_id]);

  const getCommonInfo = useCallback(
    user => ({
      user_id: user.id || null,
      user_title: user.full_name || null,
      user_type: user.id ? (user.shop_name ? "seller" : "buyer") : null,
      agent: navigator.userAgent,
      referrer: document.referrer,
    }),
    []
  );

  return;
};

export default useInhouseAnalytics;
