import React from "react";

import SearchSection from "./SearchSection";
import DownloadApp from "../DownloadApp";
import { useSelector } from "react-redux";

const PageHeader = props => {
  const mobileAppVersion = useSelector(({ common }) => common.mobileAppVersion);
  const isSearchEngineBot = useSelector(
    ({ common }) => common.isSearchEngineBot
  );

  return (
    <header className="page-header max-w-screen-md mx-auto shadow">
      {!mobileAppVersion && !isSearchEngineBot && <DownloadApp />}
      <SearchSection searchKeyword={props.searchKeyword} />
    </header>
  );
};

export default React.memo(PageHeader);
