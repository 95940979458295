import React from "react";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ProductTabPanel from "./ProductTabPanel";
import MerchantTabPanel from "./MerchantTabPanel";

const SearchModalTabs = ({ currentTab, onChange: setTab }) => (
  <>
    <div className="search-modal__tabs">
      <Tabs value={currentTab} onChange={setTab} variant="fullWidth">
        <Tab label="TÌM SẢN PHẨM" value="product" index="product" />
        <Tab label="TÌM NHÀ CUNG CẤP" value="merchant" index="merchant" />
      </Tabs>
    </div>
    <div className="search-modal__tab-panel">
      <ProductTabPanel value={currentTab} index="product" />
      <MerchantTabPanel value={currentTab} index="merchant" />
    </div>
  </>
);

export default SearchModalTabs;
