import React from "react";

import TabPanel from "../tab-panel";
import TrendingKeywords from "./trending-keywords";

const ProductTabPanel = ({ value, index }) => (
  <TabPanel value={value} index={index}>
    <TrendingKeywords />
  </TabPanel>
);

export default React.memo(ProductTabPanel);
