import React, { useState, useCallback, useEffect } from "react";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { updateCommonState } from "../../../redux/actions/common.action";

import Button from "@material-ui/core/Button";
import { searchEvent } from "../../../api/Analytics/enhancedEcommerce";

const Header = ({ handleModalClose, mode = "product", active }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [searchValue, setSearchValue] = useState(
    router.query.keyword || router.query.q
  );

  useEffect(() => {
    dispatch(updateCommonState("searchKeyword", searchValue));
  }, []);

  const handleSearchChange = useCallback(event => {
    setSearchValue(event.target.value);
    dispatch(updateCommonState("searchKeyword", event.target.value));
  }, []);

  const handleSubmit = useCallback(
    event => {
      event.preventDefault();
      // enhanced ecommerce
      searchEvent(searchValue);

      router.push(
        `/${
          mode === "product" ? "product" : "shop"
        }/search?keyword=${searchValue}`
      );

      handleModalClose();
    },
    [searchValue, mode]
  );

  return (
    <div className="search-modal__header">
      <div className="search-modal__input grow-width">
        <img src="/static/images/search-icon.png" alt="tim kiem" />
        <form onSubmit={handleSubmit}>
          <input
            autoFocus
            type="search"
            value={searchValue}
            onChange={handleSearchChange}
            placeholder={
              mode === "product" ? "Tìm sản phẩm" : "Tìm nhà cung cấp"
            }
          />
        </form>
      </div>
      <Button onClick={handleModalClose}>Hủy</Button>

      <style jsx>{`
        .grow-width {
          animation: growWidth 0.2s;
        }

        @keyframes growWidth {
          0% {
            width: 60%;
          }

          100% {
            width: 70%;
          }
        }
      `}</style>
    </div>
  );
};

export default React.memo(Header);
