import React, { useEffect } from "react";
import dynamic from "next/dynamic";
import { useSelector, useDispatch } from "react-redux";
import Head from "next/head";

import PageHeader from "../components/common/PageHeader";
// import Breadcrumb from "../components/common/Breadcrumb";
// import FooterBar from "../components/common/FooterBar";
// import Step4Modal from "../components/UserPage/Register/Step4Modal";

import { getLoggedInUserInfo } from "../api/Auth";
import { addUserInfo } from "../redux/actions/user.action";

import cookie from "../helpers/storage/cookie";
import useInhouseAnalytics from "../hooks/useInhouseAnalytics";
import { requestCart } from "../redux/actions/carts.action";
import { sendWebViewEvent, WebviewEvents } from "../helpers/webview";

// import ReviewsShop from "../components/ReviewShop";

const Breadcrumb = dynamic(() => import("../components/common/Breadcrumb"));
const FooterBar = dynamic(() => import("../components/common/FooterBar"));
// const PageHeader = dynamic(() => import("../components/common/PageHeader"));

const Layout = ({
  title,
  children,
  showHeader = false,
  showFooterBar = false,
  disablePadding = false,
  breadcrumb,
  searchKeyword = "",
  className = "",
}) => {
  const userId = useSelector(({ user }) => user.user.id);
  const cartToken = useSelector(({ cart }) => cart.currentCart?.token);

  const isWebview = useSelector(({ common }) => common.isWebview);
  const mobileAppVersion = useSelector(({ common }) => common.mobileAppVersion);
  const isSearchEngineBot = useSelector(
    ({ common }) => common.isSearchEngineBot
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (mobileAppVersion >= 2) {
      sendWebViewEvent(WebviewEvents.PageTitle, title);
    }
  }, [title]);

  useInhouseAnalytics("page", {
    object_id: title,
  });

  useEffect(() => {
    if (!userId) {
      const authToken = cookie.get("authToken");
      if (authToken) {
        getLoggedInUserInfo()
          .then(({ user, shop }) => {
            dispatch(addUserInfo(user, shop));
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
    if (!cartToken) {
      dispatch(requestCart());
    }
  }, []);

  let styles = { padding: disablePadding ? "0" : "0 0.5rem" };

  return (
    <>
      {breadcrumb && !isWebview && <Breadcrumb {...breadcrumb} />}
      <div
        className={`tts-mobile-web mx-auto max-w-screen-md ${className}`}
        style={styles}
      >
        <Head>
          <title>
            {title ||
              "Thị Trường Sỉ: Nguồn Hàng Sỉ Online Từ Hơn 60,000 Nhà Cung Cấp"}
          </title>

          <meta httpEquiv="Accept-CH" content="Viewport-Width, Width" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, 
     user-scalable=0,viewport-fit=cover"
          />
        </Head>
        {showHeader && !isWebview && (
          <>
            <div>
              <PageHeader searchKeyword={searchKeyword} />
              <div className="h-[calc(60px+env(safe-area-inset-top))]"></div>
              {!isSearchEngineBot && !mobileAppVersion && (
                <div className="h-[52px]"></div>
              )}
            </div>
          </>
        )}
        {children}
        {showFooterBar && !(mobileAppVersion >= 2) && !isWebview && (
          <FooterBar />
        )}
      </div>
    </>
  );
};

export default Layout;
