import React, { useState, useCallback } from "react";

import Modal from "@material-ui/core/Modal";
import Header from "./header";
import Tabs from "./tabs";

const SearchModal = ({ active, onClose }) => {
  const [currentTab, setCurrentTab] = useState("product");

  const handleTabChange = useCallback(
    (event, tabValue) => setCurrentTab(tabValue),
    []
  );

  return (
    <Modal open={active} onClose={onClose}>
      <div className="search-modal">
        <Header handleModalClose={onClose} active={active} mode={currentTab} />
        <Tabs currentTab={currentTab} onChange={handleTabChange} />
      </div>
    </Modal>
  );
};

export default React.memo(SearchModal);
