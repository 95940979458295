import React, { useEffect, useRef, useState, useCallback } from "react";
import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import { useRouter } from "next/router";

import SearchModal from "../../../common/SearchModal";
import { requestNotifications } from "#Root/redux/actions/notifications.action";

import cookie from "../../../../helpers/storage/cookie";
import {
  AccountCircleOutlined,
  ShoppingCartOutlined,
} from "@material-ui/icons";

const SearchSection = props => {
  const Router = useRouter();
  const dispatch = useDispatch();
  const totalUnseen = useSelector(
    ({ notifications }) => notifications.total_unseen
  );
  const [modalOpen, setModalOpen] = useState(false);

  const searchRef = useRef(null);

  useEffect(() => {
    const authToken = cookie.get("authToken");

    if (authToken) {
      dispatch(requestNotifications("?limit=10"));
    }
  }, []);

  const toggleModalOpen = useCallback(
    () => setModalOpen(active => !active),
    []
  );

  return (
    <>
      <SearchModal active={modalOpen} onClose={toggleModalOpen} />
      <div className="page-header__searching" ref={searchRef}>
        <Link href="/">
          <img
            src="/static/images/logo.png"
            className="page-header__logo mt-1"
            width={115}
            height={26}
            alt="Thị Trường Sỉ - Nguồn Hàng Sỉ - Chợ Sỉ Online"
            fetchpriority="high"
            decoding="async"
          />
        </Link>
        <div className="page-header__search-field" onClick={toggleModalOpen}>
          <img src="/static/images/search-icon.png" alt="tim-san-pham-si" />
          <span
            className="overflow-hidden overflow-ellipsis text-sm"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
            }}
          >
            {props.searchKeyword ? props.searchKeyword : "Bạn đang tìm gì?"}
          </span>
        </div>
        {Router.pathname != "/" && (
          <>
            {/* <div
            className="page-header__notifications mr-2 ml-1"
            onClick={() => Router.push("/notifications")}
          >
            <Badge badgeContent={totalUnseen} color="error">
              {Router.pathname === "/notifications" ? (
                <NotificationsIcon />
              ) : (
                <NotificationsNoneIcon />
              )}
            </Badge>
          </div> */}
            <div
              className="page-header__myaccount mr-2"
              onClick={() => Router.push("/order/cart.html")}
            >
              <ShoppingCartOutlined fontSize="small"></ShoppingCartOutlined>
            </div>
            {/* <div
            className="page-header__myaccount mr-2"
            onClick={() => Router.push("/user")}
          >
            <AccountCircleOutlined></AccountCircleOutlined>
          </div> */}
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(SearchSection);
