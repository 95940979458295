import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import Icon from "@material-ui/core/Icon";
import Tag from "../../Tag";

import getTrendingKeywords from "#Root/api/TrendingKeywords";
import {
  setTrendingSearchKeywords,
  updateCommonState,
} from "../../../../redux/actions/common.action";
import { getSearchSuggest } from "../../../../api/Products";
import useDebounce from "../../../../hooks/useDebounce";

const TrendingKeywords = props => {
  const dispatch = useDispatch();
  const keywords = useSelector(({ common }) => common.trendingKeywords);
  const searchKeyword = useSelector(({ common }) => common.searchKeyword);
  const suggestSearchKeywords = useSelector(
    ({ common }) => common.suggestSearchKeywords
  );
  const searchKeywordDebounced = useDebounce(searchKeyword, 500);

  useEffect(() => {
    if (!keywords.length) {
      getTrendingKeywords().then(keywords =>
        dispatch(setTrendingSearchKeywords(keywords))
      );
    }
  }, []);

  useEffect(() => {
    getSearchSuggest({ q: searchKeyword, match: "broad" }).then(results =>
      dispatch(updateCommonState("suggestSearchKeywords", results || []))
    );
  }, [searchKeywordDebounced]);

  const goToSearchPage = useCallback(keyword => {
    window.location.href = `/product/search?keyword=${keyword}&searchType=product`;
  }, []);

  return (
    <div className="search-modal__trending-keywords slide-down">
      <div className="mb-2">
        <Icon>
          <TrendingUpIcon />
        </Icon>
        <span className="font-semibold ml-2">Xu hướng tìm kiếm</span>
      </div>
      <div>
        {!searchKeyword &&
          keywords.slice(0, 10).map(keyword => (
            <Tag
              key={keyword._id}
              onClick={() => goToSearchPage(keyword.keyword)}
            >
              {keyword.keyword}
            </Tag>
          ))}
        {searchKeyword &&
          suggestSearchKeywords.map(k => (
            <Tag onClick={() => goToSearchPage(k.keyword)}>
              {decodeURIComponent(k.keyword)}
            </Tag>
          ))}
      </div>

      <style jsx>{`
        .slide-down {
          animation: slideDown 0.3s;
        }

        @keyframes slideDown {
          0% {
            opacity: 0;
            transform: translateY(-50px);
          }
          100% {
            opacity: 1;
            transform: translateY(0px);
          }
        }
      `}</style>
    </div>
  );
};

export default React.memo(TrendingKeywords);
